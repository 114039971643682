import { mapActions, mapGetters, mapMutations } from "vuex";
import Banner from "./components/banner/index.vue";
import Product from "@/components/product/index.vue";
import { Carousel, Slide } from "vue-carousel";
import { globalLoader } from "@/helpers/variables";

import staticContent from "@/components/static-content/index.vue";
import Vue from "vue";

export default Vue.extend({
    name: "home",
    components: {
        Banner,
        Product,
        Carousel,
        Slide,
        staticContent,
    },
    data() {
        return {
            videoStatus: false,
            videoVisible: false,
            instagramPosts: [],
            newProducts: [],
            bestSellers: [],
        };
    },
    metaInfo() {
        if (this.homePage) {
            return {
                title: `${this.homePage.title} Alphascience`,
                meta: [
                    { n: "description", c: this.homePage.metaDescription, id: "description" },
                    { n: "keywords", c: this.homePage.metaKeywords, id: "keywords" },
                    { n: "og:title", c: this.homePage.metaTitle, id: "og:title" },
                    { n: "og:description", c: this.homePage.metaDescription, id: "og:description" },
                ],
            };
        }
    },
    mounted() {
        this.fetchHeaderMenu();
    },
    created() {
        // window.addEventListener('scroll', this.videoScroll);
        this.showPopups();
        this.fetchContent();
    },
    computed: {
        ...mapGetters({
            homePage: "pages/page",
            isAuthenticated: "auth/isAuthenticated",
            favourites: "favorites/whichList",
            headerMenu: "setting/headerMenu",
            variables: "setting/variables",
        }),
        instagramName() {
            return this.variables?.homePage?.instagramAccountName
        },
        bestSellerItems() {
            if (this.isAuthenticated) {
                if (!this.bestSellers) return [];
                if (!this.favourites) return [];
                return this.bestSellers.map(e => {
                    const item = this.favourites.find(t => t.productId === e.id);
                    e.selected = !!item;
                    return e;
                });
            } else {
                return this.bestSellers;
            }
        },

        newItemsList() {
            if (this.isAuthenticated) {
                if (!this.newProducts) return [];
                if (!this.favourites) return [];
                return this.newProducts.map(e => {
                    const item = this.favourites.find(t => t.productId === e.id);
                    e.selected = !!item;
                    return e;
                });
            } else {
                return this.newProducts;
            }
        },
    },
    methods: {
        ...mapMutations({
            changeRestorePopup: "popups/CHANGE_RESTORE_POPUP",
            changePaymentInfoPopup: "popups/CHANGE_PAYMENT_INFO_POPUP",
            resetBasket: "basket/RESET_BASKET",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeSuccessPayment: "popups/CHANGE_SUCCESS_PAYMENT_POPUP",
        }),
        ...mapActions({
            fetchHeaderMenu: "setting/GET_HEADER_MENU",
            getInstagramPosts: "home/INSTAGRAM_POSTS",
            login: "auth/GET_TOKEN",
            fetchHomePage: "pages/GET_PAGE",
            fetchNewProducts: "home/GET_NEW_PRODUCTS",
            fetchBestSellers: "home/GET_BESTSELLER",
            fetchInstagramPosters: "home/GET_INSTAGRAM_POSTERS",
        }),
        showPopups() {
            if (this.$route.query.hasOwnProperty("email") && this.$route.query.hasOwnProperty("token")) {
                this.changeRestorePopup(true);
            }

            if (!this.isAuthenticated) {
                if (this.$route.query.hasOwnProperty("email") && !this.$route.query.hasOwnProperty("token")) {
                    this.changeLoginPopup(true);
                }
                if (!this.$route.query.hasOwnProperty("email") && !this.$route.query.hasOwnProperty("token")) {
                    this.changeRestorePopup(false);
                    // this.changeLoginPopup(true)
                }
            }

            if (this.$route.query.hasOwnProperty("payment-popup")) {
                this.changeSuccessPayment({status: true, payload: {
                    notCall: Number.parseInt(this.$route.query["need-confirm"] || 0) === 1
                }});
            }

            if (this.$route.query.hasOwnProperty("payment")) {
                this.changePaymentInfoPopup(true);
                if (this.$route.query.payment === "success") {
                    this.resetBasket();
                } else {
                    this.$router.push({ name: "buy-flow-order" }).catch(() => {});
                }
            }
        },
        async fetchContent() {
            globalLoader(true);

            // this.instagramAuth()

            await Promise.all([
                this.fetchHomePage("home"),
                this.fetchNewProducts().then(products => (this.newProducts = products)),
                this.fetchBestSellers().then(products => (this.bestSellers = products)),
                this.getInstagramPosts().then(instagramPosts => (this.instagramPosts = instagramPosts)),
            ]);

            // this.fetchInstagramPosters()
            if (this.homePage && this.homePage.video && this.homePage.video.length) {
                this.$refs.video.src = this.homePage.video;
            }

            globalLoader(false);
        },
        videoScroll() {
            if (document.querySelectorAll("video").length > 0) {
                let windowHeight = window.innerHeight,
                    videoEl = document.querySelectorAll("video");

                let playButton = document.getElementById("playButton");

                for (var i = 0; i < videoEl.length; i++) {
                    var thisVideoEl = videoEl[i],
                        videoHeight = thisVideoEl.clientHeight,
                        videoClientRect = thisVideoEl.getBoundingClientRect().top;

                    if (
                        videoClientRect <= windowHeight - videoHeight * 0.5 &&
                        videoClientRect >= 0 - videoHeight * 0.5
                    ) {
                        this.videoStatus = true;
                        playButton.click();
                    } else {
                        this.videoStatus = false;
                        playButton.click();
                    }
                }
            }
        },
        playVideo() {
            this.videoStatus = !this.videoStatus;
            this.videoStatus ? this.$refs.video.play() : this.$refs.video.pause();
        },
        // play() {
        //   this.videoStatus = true
        //   this.$refs.video.play()
        // },
        // pause() {
        //   this.videoStatus = false
        //   this.$refs.video.pause()
        // },
        carouselNav($event, direction) {
            const carousel = this.$refs["carouselBestSellers"];
            carousel.advancePage(direction);
        },
        carouselNav2($event, direction) {
            const carousel = this.$refs["carouselNewProducts"];
            carousel.advancePage(direction);
        },
        redirectInstagram() {
            window.open(this.variables?.homePage?.instagramAccountLink, "_blank");
        },
    },
});
